<!--20240530_1-->
<template>
  <div class="edu-frame">
    <edu-nav-bar title="停课通知" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
    <div class="content-frame">
      <div class="message-item" v-for="(data, index) in datas" :key="'msg_' + index" @click="toAdd(data.wxMsgMasterId)">
<!--        <div class="message-Line" style="font-size: 16px">
          <div class="message-Title">通知名称:</div>
          <div>{{data.caption}}</div>
        </div>-->
        <div class="message-Line">
          <div class="message-Title">班级:</div>
          <div>{{data.className}}</div>
        </div>
        <div class="message-Line">
          <div class="message-Title">创建人:</div>
          <div>{{ data.fullName }}</div>
        </div>
        <div class="message-Line">
          <div class="message-Title">创建时间</div>
          <div>{{ filterDateTime(data.createTime) }}</div>
        </div>
      </div>

    </div>
    <div class="bottom-frame">
      <van-button square block type="primary" @click="toAdd(null)" :loading="submitting">新增请假</van-button>
    </div>
  </div>
</template>
<script>
import EduNavBar from "@/components/EduNavBar.vue";
import {Button} from "vant";
import BaomingApi from "@/api/BaomingApi";
import Tools from "@/api/Tools";
import XEUtils from "xe-utils";
export default {
  components: {
    EduNavBar,
    VanButton: Button,
  },
  data() {
    return {
      show: false,
      submitting: false,
      datas: []
    }
  },
  methods: {
    filterDateTime(date) {
      return date == null ? null : XEUtils.toDateString(XEUtils.toStringDate(date, 'yyyyMMddHHmmss'))
    },
    toAdd(wxMsgMasterId) {
      this.$router.push({ name: 'tktzedit', query: { wxMsgMasterId: wxMsgMasterId } })
    },
    getWxMsgMasterByCustId() {
      BaomingApi.getWxMsgMasterByCustId({ custId: Tools.getCurCust().custId }).then(response => {
        this.datas = response.res
      })
    }
  },
  mounted() {
    this.getWxMsgMasterByCustId()
  }
}
</script>
<style scoped>
.message-item {
  width: 92%;margin: 10px auto;padding: 5px 8px; background-color: white;
}
.message-Line {
  display: flex;
  line-height: 28px;
  font-size: 14px;
}
.message-Title {
  width: 80px;
}
</style>
